div.hero-callout(ng-hide='isVisibleForm')
    div.content
        div(coming-soon)
            div.logo
            div.heading.m5 Creative Design Agency, focused on creating beautiful products #[span.blue +] experiences
            div.sub-heading Sit tight. Our full site experience is coming soon.
            button.reach-out(ng-click='open($event)') Reach Out
            hr.hr
div.email-form(ng-show='isVisibleForm')
    div.content
        div(request-form)
