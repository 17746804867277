form(role='form', ng-submit='handleSubmit()', name='request', ng-hide='isComplete', ng-class='{\'is-loading\': isLoading}')
    div.row
        div.form-group.col-12
            h3 We'd love to here from you!
        div.form-group.col-xs-12.col-sm-6(ng-class='{\'has-success\': request.name.$valid}')
            label(for='name') Name
            input.form-control(type='text', name='name', placeholder='Name', ng-model='name', ng-required='true', ng-disabled='isSubmitted')

        div.form-group.col-xs-12.col-sm-6(ng-class='{\'has-success\': request.company.$valid}')
            label(for='company') Company
            input.form-control(type='text', name='company', placeholder='Company', ng-model='company', ng-required='true', ng-disabled='isSubmitted')

        div.form-group.col-xs-12.col-sm-6(ng-class='{\'has-success\': request.email.$valid}')
            label(for='email') email
            input.form-control(type='email', name='email', placeholder='Email', ng-model='email', ng-required='true', ng-disabled='isSubmitted')

        div.form-group.col-xs-12.col-sm-6
            label(for='budget') budget
            input.form-control(type='number', name='budget', placeholder='Budget', ng-model='budget', ng-disabled='isSubmitted')

        div.form-group.col-12(ng-class='{\'has-success\': request.message.$valid}')
            label(for='message') message
            textarea.form-control(type='text', name='message', placeholder='Message', ng-model='message', ng-required='true', ng-disabled='isSubmitted')

        div.form-group.col-12.m-no-margin
            span.help All fields required *
            button(type='submit', ng-disabled='isSubmited') Send

        input.validate-form(type='text', name='url', ng-model='url', ng-pattern='urlRegex')
        div.close(ng-click='close($event)') &times;
        div.loading(ng-show='isLoading')
            div.loader

div.thank-you(ng-show='isSuccess')
    h1 Thank you for contacting us!
    h3 You are awesome and we will get in touch as soon as we can!
    div {{messagetext}}

div.error(ng-show='isError')
    h1 Oops, an error occured!
    h3 Something went wrong when submitting the form, please try again later!
